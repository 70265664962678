import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import axios from 'axios';
import { useAuth } from '../../contexts/AuthContext';
import { useAccount, useChainId, usePublicClient } from 'wagmi';
import emptyIcon from './empty.png';
import { useEntity } from '../../contexts/EntityContext';
import { contracts } from '../../constants';
import { useWriteContracts, useCallsStatus } from 'wagmi/experimental';
import { useError } from '../../contexts/ErrorContext';
import { Abi, decodeEventLog, zeroAddress } from 'viem';
import ViewToken from './ViewToken';

export default function Safes() {
  const { auth } = useAuth();
  const chainId = useChainId();
  const { entity } = useEntity();
  const [safeClasses, setSafeClasses] = useState<any>([]);
  const [hasToken, setHasToken] = useState<boolean>(false);
  const [safeContract, setSafeContract] = useState<string | null>(null);
  const publicClient = usePublicClient();
  const { address: account } = useAccount();
  const [loading, setLoading] = useState(false);
  const { writeContractsAsync: deploySafeContract } = useWriteContracts();
  const [writeContractsHash, setWriteContractsHash] = useState<any>(null);
  const { setError } = useError();
  const { data: result, refetch } = useCallsStatus({
    id: writeContractsHash || '',
  });
  const [safeContractAddress, setSafeContractAddress] = useState<
    string | undefined | null
  >(undefined);

  const capabilities = React.useMemo(
    () => ({
      paymasterService: {
        url: process.env.REACT_APP_PAYMASTER_URL,
      },
    }),
    []
  );

  /**
   * Fetch safe classes
   */
  React.useEffect(() => {
    (async () => {
      try {
        if (chainId) {
          const url = `${process.env.REACT_APP_API_URL}/v1/entities/me/safe_classes`;
          const response = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'X-Account-Id': auth?.user.account_id,
            },
          });
          const result = response.data.result;
          setSafeClasses(result);
          for (let shareClass of result) {
            if (shareClass.token_address) setHasToken(true);
          }
        }
      } catch (error: any) {
        console.error(error);
      }
    })();
  }, [auth?.token]);

  /**
   * Fetch SAFE contract
   */
  React.useEffect(() => {
    (async () => {
      try {
        if (chainId) {
          const url = `${process.env.REACT_APP_API_URL}/v1/entities/me/safe_contract`;
          const response = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'X-Account-Id': auth?.user.account_id,
            },
          });
          const result = response.data.result;

          if (result) setHasToken(true);
          setSafeContract(result);
        }
      } catch (error: any) {
        console.error(error);
      }
    })();
  }, [auth?.token]);

  const deploySafe = async () => {
    try {
      setLoading(true);
      const params = {
        address: contracts[chainId!].TREXFactory.address as `0x${string}`,
        abi: contracts[chainId!].TREXFactory.abi,
        functionName: 'deployTREXSuite',
        args: [
          new Date().toISOString(), // salt
          {
            name: `${entity.short_name} SAFEs`,
            symbol: 'SAFE',
            uri: '',
            irAgents: [account!],
            tokenAgents: [account!],
            complianceModules: [],
            complianceSettings: [],
            ONCHAINID: zeroAddress,
            owner: account!,
            irs: zeroAddress,
          },
          {
            claimTopics: [],
            issuers: [],
            issuerClaims: [],
          },
          3, // TokenType for SAFE
        ],
      };

      // Simulate transaction
      await publicClient?.estimateContractGas({
        ...params,
        account,
      });

      const result = await deploySafeContract({
        contracts: [params],
        account,
        chainId,
        capabilities,
      });
      setWriteContractsHash(result);
    } catch (error) {
      setError(error);
      console.error('Error deploying SAFE contract:', error);
    }
  };

  // Setup polling when txId is set
  useEffect(() => {
    if (!writeContractsHash) return;

    const interval = setInterval(() => {
      refetch();
    }, 1000);

    // Clear interval when component unmounts or writeContractsHash changes
    return () => {
      clearInterval(interval);
    };
  }, [writeContractsHash, refetch]);

  // Wait for SAFE contract to be deployed
  useEffect(() => {
    if (
      writeContractsHash &&
      result?.status === 'CONFIRMED' &&
      safeContractAddress === undefined
    ) {
      (async () => {
        try {
          const filteredLogs = result.receipts?.[0]?.logs.filter((l: any) => {
            return (
              l.address.toLowerCase() ===
              contracts[chainId!].TREXFactory.address.toLowerCase()
            );
          });

          const mappedLogs = filteredLogs!.map((l: any) =>
            decodeEventLog({
              abi: contracts[chainId!].TREXFactory.abi as Abi,
              data: l.data,
              topics: l.topics,
            })
          );

          const event: any = mappedLogs?.filter(
            (l: any) => l.eventName === 'TREXSuiteDeployed'
          )[0];

          if (!event) {
            console.error('TREXSuiteDeployed event not found.');
            return;
          }

          const deployedAddress = event.args._token;
          const identityRegistryAddress = event.args._ir;

          setSafeContractAddress(deployedAddress);

          // Save the SAFE contract address to backend
          await axios.post(
            `${process.env.REACT_APP_API_URL}/v1/tokens`,
            {
              asset_subtype: 'safe',
              contract_address: deployedAddress,
              registry_address: identityRegistryAddress,
              identity_address: null,
            },
            {
              headers: {
                Authorization: `Bearer ${auth?.token}`,
                'X-Account-Id': auth?.user.account_id,
              },
            }
          );
          setLoading(false);
          alert('Successfully deployed SAFE contract');
          setHasToken(true);
          setWriteContractsHash(null);
        } catch (error) {
          console.error('Error tokenizing:', error);
        }
      })();
    }
  }, [result]);

  return (
    <>
      {hasToken ? (
        <ViewToken />
      ) : (
        <>
          {/* Breadcrumb */}
          <div className="border-bottom">
            <div className="container-fluid py-3">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <li className="breadcrumb-item active">Safes</li>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <Container fluid>
            <Row className="h-100">
              <Col md={{ span: 8, offset: 2 }} className="my-4">
                <div className="d-flex justify-content-between align-items-center">
                  <h4 className="mb-0">Safes Management Dashboard</h4>
                  <button
                    onClick={deploySafe}
                    className="btn btn-primary"
                    disabled={loading}
                  >
                    {loading ? 'Creating...' : 'New Token'}
                  </button>
                </div>
                <div className="justify-content-center align-items-center d-flex mt-5">
                  <img src={emptyIcon} className="img-fluid" />
                </div>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </>
  );
}
