import React, { useState, useEffect } from 'react';
import {
  useAccount,
  usePublicClient,
  useWalletClient,
  useChainId,
  useWriteContract,
  useReadContract,
} from 'wagmi';
import { useAuth } from '../../../contexts/AuthContext';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import SAFE from '@capsign/contracts/artifacts/src/securities/token/SAFE.sol/SAFE.json';
import { useOffering } from '../../../contexts/OfferingContext';
import { useError } from '../../../contexts/ErrorContext';
import TokenInputDropdown from './TokenInputDropdown';

// Import images
import usdcImage from '../../../assets/images/usdc.png';
import ethImage from '../../../assets/images/eth.png';
import { Abi, decodeEventLog, parseEventLogs } from 'viem';
import { contracts } from '../../../constants';
import { useEntity } from '../../../contexts/EntityContext';

const CreateOfferingSafeClass: React.FC = () => {
  const { address: account } = useAccount();
  const publicClient = usePublicClient();
  const { data: walletClient } = useWalletClient();
  const { auth } = useAuth();
  const { offeringId } = useParams();
  const chainId = useChainId();
  const navigate = useNavigate();
  const { offering, fetchOffering } = useOffering();
  const { setError } = useError();
  const [loading, setLoading] = useState(false);
  const { entity } = useEntity();

  const [formData, setFormData] = useState<any>({
    discount: 0,
    valuationCap: 0,
    mostFavoredNation: false,
    currency: '',
    boardApprovalDate: '',
    stockholderApprovalDate: '',
    considerationText: '',
    proRataPercentage: 0,
    seniority: 1,
    uri: '',
  });

  const [txHash, setTxHash] = useState<`0x${string}` | undefined>();
  const [contractAddress, setContractAddress] = useState<
    `0x${string}` | undefined
  >();

  const {
    data: factoryTxHash,
    error: factoryError,
    writeContractAsync: deployTREXSuite,
  } = useWriteContract();

  const [safeClasses, setSafeClasses] = useState<any[]>([]);
  const [selectedSafeClassId, setSelectedSafeClassId] = useState<string>('');
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const handleFormChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value, type, checked } = event.target as HTMLInputElement;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  useEffect(() => {
    (async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/entities/${entity.entity_id}/tokens`,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'X-Account-Id': auth?.user.account_id,
          },
        }
      );
      const safeToken = response.data.result.find(
        (token: any) => token.token_symbol === 'SAFE'
      )?.token_address;
      setContractAddress(safeToken);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (!contractAddress) return;
      // Update the backend with contract address
      await axios.put(
        `${process.env.REACT_APP_API_URL}/v1/offerings/${offeringId}`,
        {
          contract_address: contractAddress,
        },
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'X-Account-Id': auth?.user.account_id,
          },
        }
      );
    })();
  }, [contractAddress]);

  useEffect(() => {
    const fetchSafeClasses = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/entities/${entity.entity_id}/safe_classes`,
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'X-Account-Id': auth?.user.account_id,
            },
          }
        );
        setSafeClasses(response.data.result);
      } catch (error) {
        console.error('Error fetching safe classes:', error);
        setError('Failed to fetch existing safe classes.');
      }
    };

    fetchSafeClasses();
  }, [auth?.token, auth?.user.account_id]);

  const handleSafeClassSelect = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const classId = event.target.value;
    setSelectedSafeClassId(classId);
    setIsEditing(false);

    if (classId === '') {
      // If no class is selected, allow creating new terms
      setFormData({
        discount: 0,
        valuationCap: 0,
        mostFavoredNation: false,
        currency: '',
        boardApprovalDate: '',
        stockholderApprovalDate: '',
        considerationText: '',
        proRataPercentage: 0,
        seniority: 1,
        uri: '',
      });
      return;
    }

    // Fetch the details of the selected safe class
    try {
      const safeClass = safeClasses.find(
        (safeClass) => safeClass.class_id === classId
      );

      // Populate formData with safe class details
      setFormData({
        discount: safeClass.discount,
        valuationCap: safeClass.valuation_cap,
        mostFavoredNation: safeClass.most_favored_nation,
        currency: safeClass.currency,
        boardApprovalDate: safeClass.board_approval_date
          ? new Date(safeClass.board_approval_date).toISOString().split('T')[0]
          : '',
        stockholderApprovalDate: safeClass.stockholder_approval_date
          ? new Date(safeClass.stockholder_approval_date)
              .toISOString()
              .split('T')[0]
          : '',
        considerationText: safeClass.consideration_text,
        proRataPercentage: safeClass.pro_rata_percentage,
        seniority: safeClass.seniority,
        uri: safeClass.uri,
      });
    } catch (error) {
      console.error('Error fetching safe class details:', error);
      setError('Failed to fetch selected safe class details.');
    }
  };

  const createSafeClass = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      // Now call createSafeTerms
      const safeContract = {
        address: contractAddress!,
        abi: SAFE.abi,
      };

      // Ensure the user is an agent
      const isAgent = await publicClient?.readContract({
        ...safeContract,
        functionName: 'isAgent',
        args: [account!],
      });
      if (!isAgent) {
        throw new Error('User is not an agent');
      }

      // Prepare the SAFE terms
      const safeClass = {
        currency: formData.currency,
        discount: Number(formData.discount),
        valuationCap: Number(formData.valuationCap),
        uri: formData.uri,
      };
      console.log('calling createSafeClass', safeClass);

      const gas = await publicClient?.estimateContractGas({
        ...safeContract,
        functionName: 'createSafeClass',
        args: [safeClass],
        account: account!,
      });
      console.log('gas', gas);

      // Call the createSafeClass function
      const createSafeClassHash = await walletClient?.writeContract({
        ...safeContract,
        functionName: 'createSafeClass',
        args: [safeClass],
        account: account!,
        gas,
      });

      console.log('calling waitForTransactionReceipt');

      const createSafeClassReceipt =
        await publicClient?.waitForTransactionReceipt({
          hash: createSafeClassHash!,
        });

      // Extract tokenClassId from the ClassCreated event
      let tokenClassId: bigint | undefined;

      if (createSafeClassReceipt?.logs) {
        for (const log of createSafeClassReceipt.logs) {
          try {
            const event = parseEventLogs({
              abi: SAFE.abi as Abi,
              logs: [log],
            })[0];
            if (event.eventName === 'ClassCreated') {
              tokenClassId = (event.args as any).classId;
              break;
            }
          } catch (error) {
            // Not the event we are looking for
          }
        }
      }

      // Create the safe terms on the backend
      await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/offerings/${offeringId}/safe_class`,
        {
          discount: formData.discount,
          valuation_cap: formData.valuationCap,
          most_favored_nation: formData.mostFavoredNation,
          currency: formData.currency,
          board_approval_date: formData.boardApprovalDate,
          stockholder_approval_date: formData.stockholderApprovalDate,
          consideration_text: formData.considerationText,
          pro_rata_percentage: formData.proRataPercentage,
          seniority: formData.seniority,
          uri: formData.uri,
          tx_hash: createSafeClassHash!,
          token_class_id: Number(tokenClassId),
        },
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'X-Account-Id': auth?.user.account_id,
          },
        }
      );

      // Navigate to next page or show confirmation
      navigate(`/offerings/${offeringId}/review`);
    } catch (error: any) {
      console.error('Error creating SAFE class:', error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    if (selectedSafeClassId === '') {
      // Create a new safe class
      await createSafeClass(event);
    } else {
      // Associate existing safe class with the offering
      try {
        await axios.post(
          `${process.env.REACT_APP_API_URL}/v1/offerings/${offeringId}/safe_class`,
          {
            class_id: selectedSafeClassId,
          },
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'X-Account-Id': auth?.user.account_id,
            },
          }
        );
        navigate(`/offerings/${offeringId}/review`);
      } catch (error: any) {
        console.error('Error associating safe class:', error);
        setError('Failed to associate safe class with the offering.');
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    if (offeringId && !offering) fetchOffering(offeringId);
  }, [offeringId, fetchOffering]);

  const recommendedTokens = [
    {
      address: '0x036CbD53842c5426634e7929541eC2318f3dCF7e',
      name: 'USD Coin',
      imageUrl: usdcImage,
    },
    {
      address: '0x0000000000000000000000000000000000000000',
      name: 'Ethereum',
      imageUrl: ethImage,
    },
  ];

  return (
    <Container className="my-5">
      <div className="row">
        <div className="col-8 offset-2">
          <h2>SAFE Offering Terms</h2>
          <Form onSubmit={contractAddress ? createSafeClass : handleSubmit}>
            {loading && <p>Deploying SAFE contract...</p>}
            {txHash && <p>Transaction hash: {txHash}</p>}
            {contractAddress && (
              <p>ERC-7752 SAFE contract address: {contractAddress}</p>
            )}
            <h4>SAFE Class Details</h4>
            {/* Dropdown to select existing safe classes */}
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formSafeClassSelect"
            >
              <Form.Label column sm={2}>
                Select Existing Safe Class
              </Form.Label>
              <Col sm={10}>
                <Form.Select
                  value={selectedSafeClassId}
                  onChange={handleSafeClassSelect}
                >
                  <option value="">New</option>
                  {safeClasses.map((safeClass) => (
                    <option key={safeClass.class_id} value={safeClass.class_id}>
                      {safeClass.class_name}
                    </option>
                  ))}
                </Form.Select>
              </Col>
            </Form.Group>

            {/* Discount */}
            <Form.Group as={Row} className="mb-3" controlId="formDiscount">
              <Form.Label column sm={2}>
                Discount (%)
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="number"
                  name="discount"
                  value={formData.discount}
                  onChange={handleFormChange}
                  readOnly={selectedSafeClassId !== ''}
                />
              </Col>
            </Form.Group>

            {/* Valuation Cap */}
            <Form.Group as={Row} className="mb-3" controlId="formValuationCap">
              <Form.Label column sm={2}>
                Valuation Cap
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="number"
                  name="valuationCap"
                  value={formData.valuationCap}
                  onChange={handleFormChange}
                  readOnly={selectedSafeClassId !== ''}
                />
                <Form.Text className="text-muted">
                  Enter 0 if there is no valuation cap.
                </Form.Text>
              </Col>
            </Form.Group>

            {/* Most Favored Nation */}
            <Form.Group as={Row} className="mb-3" controlId="formMFN">
              <Form.Label column sm={2}>
                Most Favored Nation
              </Form.Label>
              <Col sm={10}>
                <Form.Check
                  type="checkbox"
                  name="mostFavoredNation"
                  checked={formData.mostFavoredNation}
                  onChange={handleFormChange}
                  disabled={selectedSafeClassId !== ''}
                />
              </Col>
            </Form.Group>

            {/* Investment Token */}
            <Form.Group as={Row} className="mb-3" controlId="formCurrency">
              <Form.Label column sm={2}>
                Currency Address
              </Form.Label>
              <Col sm={10}>
                <TokenInputDropdown
                  recommendedTokens={recommendedTokens}
                  value={formData.currency}
                  onChange={(value) =>
                    setFormData({ ...formData, currency: value })
                  }
                  disabled={selectedSafeClassId !== ''}
                />
              </Col>
            </Form.Group>

            {/* Board Approval Date */}
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formBoardApprovalDate"
            >
              <Form.Label column sm={2}>
                Board Approval Date
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="date"
                  name="boardApprovalDate"
                  value={formData.boardApprovalDate}
                  onChange={handleFormChange}
                  required
                />
              </Col>
            </Form.Group>

            {/* Stockholder Approval Date */}
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formStockholderApprovalDate"
            >
              <Form.Label column sm={2}>
                Stockholder Approval Date
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="date"
                  name="stockholderApprovalDate"
                  value={formData.stockholderApprovalDate}
                  onChange={handleFormChange}
                />
              </Col>
            </Form.Group>

            {/* Consideration Text */}
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formConsiderationText"
            >
              <Form.Label column sm={2}>
                Consideration Text
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  as="textarea"
                  name="considerationText"
                  rows={3}
                  placeholder="Describe the consideration for the SAFE"
                  value={formData.considerationText}
                  onChange={handleFormChange}
                />
              </Col>
            </Form.Group>

            {/* Pro Rata Percentage */}
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formProRataPercentage"
            >
              <Form.Label column sm={2}>
                Pro Rata Rights (%)
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="number"
                  name="proRataPercentage"
                  value={formData.proRataPercentage}
                  onChange={handleFormChange}
                />
              </Col>
            </Form.Group>

            {/* Seniority */}
            <Form.Group as={Row} className="mb-3" controlId="formSeniority">
              <Form.Label column sm={2}>
                Seniority
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="number"
                  name="seniority"
                  value={formData.seniority}
                  onChange={handleFormChange}
                  required
                />
              </Col>
            </Form.Group>

            {/* URI */}
            <Form.Group as={Row} className="mb-3" controlId="formURI">
              <Form.Label column sm={2}>
                Metadata URI
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  name="uri"
                  placeholder="Enter metadata URI"
                  value={formData.uri}
                  onChange={handleFormChange}
                />
              </Col>
            </Form.Group>

            {/* Option to Reset Selection and Create New Safe Terms */}
            {selectedSafeClassId && !isEditing && (
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formResetSelection"
              >
                <Col sm={{ span: 10, offset: 2 }}>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      setSelectedSafeClassId('');
                      setIsEditing(true);
                      setFormData({
                        discount: 0,
                        valuationCap: 0,
                        mostFavoredNation: false,
                        currency: '',
                        boardApprovalDate: '',
                        stockholderApprovalDate: '',
                        considerationText: '',
                        proRataPercentage: 0,
                        seniority: 1,
                        uri: '',
                      });
                    }}
                  >
                    Create New Safe Terms
                  </Button>
                </Col>
              </Form.Group>
            )}

            <Button variant="primary" type="submit" disabled={loading}>
              {loading ? 'Deploying...' : 'Save and Continue'}
            </Button>
          </Form>
        </div>
      </div>
    </Container>
  );
};

export default CreateOfferingSafeClass;
