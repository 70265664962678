import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../../contexts/AuthContext';
import { Card, Container, Button, Row, Col, Table } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { contracts } from '../../../constants';
import {
  useAccount,
  useChainId,
  usePublicClient,
  useWalletClient,
  useWriteContract,
} from 'wagmi';
import {
  Abi,
  AbiFunction,
  concat,
  encodeAbiParameters,
  keccak256,
  parseUnits,
  zeroAddress,
  pad,
  erc20Abi,
} from 'viem';

const baseSepoliaCreate2Deployer = '0x914d7Fec6aaC8cd542e72Bca78B30650d45643d7';

const ReviewOffering: React.FC = () => {
  const { auth } = useAuth();
  const { offeringId } = useParams();
  const navigate = useNavigate();
  const [offering, setOffering] = useState<any>(null);
  const [safeClass, setSafeClass] = useState<any>(null);
  const [documents, setDocuments] = useState<any[]>([]);
  const { data: walletClient } = useWalletClient();
  const publicClient = usePublicClient();
  const chainId = useChainId();
  const { address } = useAccount();
  const { writeContractAsync } = useWriteContract();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchOfferingDetails = async () => {
      try {
        const response = await axios({
          url: `${process.env.REACT_APP_API_URL}/v1/offerings/${offeringId}`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'X-Account-Id': auth?.user.account_id,
          },
        });
        const offeringData = response.data.result;
        setOffering(offeringData);
        setSafeClass(offeringData.safe_class);
        if (offeringData.documents) {
          setDocuments(offeringData.documents);
        }
      } catch (error) {
        console.error('Error fetching offering details:', error);
      }
    };
    fetchOfferingDetails();
  }, [auth?.token, offeringId]);

  const handleSubmitForApproval = async () => {
    try {
      setLoading(true);
      if (!offering || !safeClass) {
        console.error('Offering or SAFE terms are not available.');
        return;
      }

      // Retrieve the ABI and bytecode for the SafeRegD506b contract
      const offeringABI = contracts[chainId!].SafeRegD506b.abi;
      const offeringBytecode = contracts[chainId!].SafeRegD506b.bytecode;

      // Map offering type to enum value ('Primary' is 0 and 'Secondary' is 1)
      const offeringTypeEnum = offering.offering_market === 'Primary' ? 0 : 1;

      // Since the constructor has been removed, we no longer need to encode constructor arguments
      const bytecode = offeringBytecode as `0x${string}`;

      // Declare 'salt'
      const salt = keccak256(
        new TextEncoder().encode(Date.now().toString())
      ) as `0x${string}`;

      const codeHash = keccak256(bytecode);
      const computedAddress = `0x${keccak256(
        concat(['0xff', baseSepoliaCreate2Deployer, salt, codeHash])
      ).slice(-40)}`;

      // Use viem's pad function
      const saltHex = pad(salt, { size: 32, dir: 'left' }) as `0x${string}`;

      const callData = concat([saltHex as `0x${string}`, bytecode]);

      // Deploy the contract
      const hash = await walletClient?.sendTransaction({
        to: baseSepoliaCreate2Deployer,
        data: callData as `0x${string}`,
        account: address as `0x${string}`,
      });

      // Wait for the transaction receipt
      const result = await publicClient?.waitForTransactionReceipt({
        hash: hash!,
      });

      console.log('result', result);

      // Confirm the contract deployment
      const code = await publicClient?.getBytecode({
        address: computedAddress as `0x${string}`,
      });

      if (!code || code === '0x') {
        console.error('Contract deployment failed or not yet confirmed.');
        return;
      }

      // Log deployment success
      console.log('Contract deployed at:', computedAddress);

      // Add the RegD506c contract as an agent to the SAFE contract
      const safeRegD506bABI = contracts[chainId!].SafeRegD506b.abi;

      const addAgentTx = await writeContractAsync({
        abi: safeRegD506bABI,
        address: safeClass.contract_address as `0x${string}`,
        functionName: 'addAgent',
        args: [computedAddress as `0x${string}`],
        account: address as `0x${string}`,
      });

      console.log('addAgentTx', addAgentTx);

      // Wait for the addAgent transaction to be mined
      await publicClient?.waitForTransactionReceipt({
        hash: addAgentTx!,
      });

      // Prepare safeTerms matching Types.SAFE struct
      const safeTerms = {
        currency: safeClass.currency as `0x${string}`,
        discount: parseUnits(safeClass.discount.toString(), 2),
        valuationCap: parseUnits(safeClass.valuation_cap.toString(), 2),
        uri: safeClass.uri || '',
      };

      const currencyDecimals = await publicClient?.readContract({
        address: safeClass.currency as `0x${string}`,
        abi: erc20Abi,
        functionName: 'decimals',
        args: [],
      });

      console.log('currencyDecimals', currencyDecimals);

      const args = [
        offeringTypeEnum,
        address as `0x${string}`, // issuerOrSeller
        offering.offering_name,
        offering.offering_description,
        parseUnits(offering.soft_cap.toString(), currencyDecimals!),
        parseUnits(offering.minimum_investment.toString(), currencyDecimals!),
        safeClass.currency as `0x${string}`,
        `https://api.capsign.com/v1/offerings/${offeringId}`,
        safeTerms,
        safeClass.contract_address as `0x${string}`, // _safeAddress
      ];

      console.log('args', args);

      const gasEstimate = await publicClient?.estimateContractGas({
        abi: offeringABI,
        address: computedAddress as `0x${string}`,
        functionName: 'initialize',
        args,
        account: address as `0x${string}`,
      });

      console.log('gasEstimate', gasEstimate);

      // Call the initialize function
      const initializeHash = await walletClient?.writeContract({
        abi: offeringABI,
        address: computedAddress as `0x${string}`,
        functionName: 'initialize',
        args,
        account: address as `0x${string}`,
      });

      // Wait for the initialize transaction to be mined
      await publicClient?.waitForTransactionReceipt({
        hash: initializeHash!,
      });

      // Log initialization success
      console.log('Contract initialized.');

      // Submit offering for approval
      await axios({
        url: `${process.env.REACT_APP_API_URL}/v1/offerings/${offeringId}/submit`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'X-Account-Id': auth?.user.account_id,
        },
        data: {
          contract_address: computedAddress,
        },
      });

      navigate(`/offerings/${offeringId}`);
    } catch (error) {
      console.error('Error submitting offering for approval:', error);
    } finally {
      setLoading(false);
    }
  };

  if (!offering) {
    return <div>Loading...</div>;
  }

  return (
    <Container className="my-5 p-5">
      <Helmet>
        <title>Review Offering | {offering.offering_name}</title>
      </Helmet>
      <h2 className="mb-4">Review Offering</h2>

      {/* Offering Details Card */}
      <Card className="mb-4 shadow-sm">
        <Card.Body>
          <Row>
            <Col md={6}>
              <Card.Title>Offering Name</Card.Title>
              <Card.Text>{offering.offering_name}</Card.Text>
            </Col>
            <Col md={6}>
              <Card.Title>Offering Type</Card.Title>
              <Card.Text>{offering.offering_type}</Card.Text>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <Card.Title>Description</Card.Title>
              <Card.Text>{offering.offering_description}</Card.Text>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <Card.Title>Use of Funds</Card.Title>
              <Card.Text>{offering.use_of_funds}</Card.Text>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      {/* Financial Details Card */}
      <Card className="mb-4 shadow-sm">
        <Card.Body>
          <Row>
            <Col md={4}>
              <Card.Title>Exemption</Card.Title>
              <Card.Text>{offering.federal_exemption}</Card.Text>
            </Col>
            <Col md={4}>
              <Card.Title>Soft Cap</Card.Title>
              <Card.Text>${offering.soft_cap}</Card.Text>
            </Col>
            <Col md={4}>
              <Card.Title>Hard Cap</Card.Title>
              <Card.Text>${offering.hard_cap}</Card.Text>
            </Col>
          </Row>
          {offering.offering_type !== 'Post-money SAFE' && (
            <Row className="mt-3">
              <Col md={4}>
                <Card.Title>Offering Price</Card.Title>
                <Card.Text>${offering.offering_price} per share</Card.Text>
              </Col>
            </Row>
          )}
          <Row className="mt-3">
            <Col md={4}>
              <Card.Title>Investment Deadline</Card.Title>
              <Card.Text>
                {offering.investment_deadline
                  ? new Date(offering.investment_deadline).toLocaleDateString()
                  : 'N/A'}
              </Card.Text>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      {/* SAFE Terms Card */}
      {['Post-money SAFE', 'Pre-money SAFE'].includes(offering.offering_type) &&
        safeClass && (
          <Card className="mb-4 shadow-sm">
            <Card.Body>
              <Card.Title>SAFE Terms</Card.Title>
              <Row className="mt-3">
                <Col md={6}>
                  <Card.Subtitle className="mb-2">Discount</Card.Subtitle>
                  <Card.Text>{safeClass.discount}%</Card.Text>
                </Col>
                <Col md={6}>
                  <Card.Subtitle className="mb-2">Valuation Cap</Card.Subtitle>
                  <Card.Text>${safeClass.valuation_cap}</Card.Text>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={6}>
                  <Card.Subtitle className="mb-2">
                    Most Favored Nation
                  </Card.Subtitle>
                  <Card.Text>
                    {safeClass.most_favored_nation ? 'Yes' : 'No'}
                  </Card.Text>
                </Col>
                <Col md={6}>
                  <Card.Subtitle className="mb-2">
                    Investment Token
                  </Card.Subtitle>
                  <Card.Text>{safeClass.currency || 'N/A'}</Card.Text>
                </Col>
              </Row>
              {/* Include other SAFE terms as needed */}
            </Card.Body>
          </Card>
        )}

      {/* Documents Card */}
      <Card className="mb-4 shadow-sm">
        <Card.Body>
          <Card.Title>Documents</Card.Title>
          {documents && documents.length > 0 ? (
            <Table striped bordered hover className="mt-3">
              <thead>
                <tr>
                  <th>Document Type</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {documents.map((doc) => (
                  <tr key={doc.document_id}>
                    <td>{doc.document_type}</td>
                    <td>
                      <a
                        href={doc.document_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View Document
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>No documents uploaded.</p>
          )}
        </Card.Body>
      </Card>

      {/* Submit Button */}
      <div className="d-flex justify-content-between">
        <Button
          variant="secondary"
          onClick={() => navigate(`/offerings/${offeringId}/edit/details`)}
        >
          Back
        </Button>
        <Button
          variant="primary"
          onClick={handleSubmitForApproval}
          disabled={loading}
        >
          {loading ? 'Submitting...' : 'Submit for Approval'}
        </Button>
      </div>
    </Container>
  );
};

export default ReviewOffering;
