// src/pages/Offerings/Documents/OfferingDocuments.tsx

import React from 'react';
import { useOffering } from '../../../contexts/OfferingContext';
import SafeOfferingDocuments from './SafeOfferingDocuments';
import EquityOfferingDocuments from './EquityOfferingDocuments';

const OfferingDocuments: React.FC = () => {
  const { offering } = useOffering();

  if (offering?.offering_type === 'Equity') {
    return <EquityOfferingDocuments />;
  } else if (
    ['Post-money SAFE', 'Pre-money SAFE'].includes(offering?.offering_type)
  ) {
    return <SafeOfferingDocuments />;
  }

  // You can handle other offering types or provide a default case
  return <div>Unsupported offering type: {offering?.offering_type}</div>;
};

export default OfferingDocuments;
