import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, InputGroup, Spinner } from 'react-bootstrap';
import { contracts } from '../../../constants';
import { ethers } from 'ethers';
import {
  parseUnits,
  hexToNumber,
  verifyTypedData,
  Abi,
  slice,
  parseSignature,
} from 'viem';
import { useError } from '../../../contexts/ErrorContext';
import axios from 'axios';
import { useAuth } from '../../../contexts/AuthContext';
import {
  useAccount,
  useChainId,
  usePublicClient,
  useWalletClient,
  useWriteContract,
} from 'wagmi';
import {
  decimals,
  subscriptionToken,
  subscriptionTokenAddress,
} from '../../../utils';
import SelectBankAccount from '../../../components/SelectBankAccount';
import PDFViewer from './PDFViewer';
import { useToast } from '../../../contexts/ToastContext';

interface InvestModalProps {
  show: boolean;
  handleClose: () => void;
  contractAddress: string;
  offeringId: string;
  federalExemption: string;
  offeringType: string;
  onInvestmentSuccess: (investmentAmount: number) => void;
}

const InvestModal: React.FC<InvestModalProps> = ({
  show,
  handleClose,
  contractAddress,
  offeringId,
  onInvestmentSuccess,
}) => {
  const [investmentAmount, setInvestmentAmount] = useState<number | string>('');
  const [paymentMethod, setPaymentMethod] = useState<string>('ach');
  const { setError } = useError();
  const { setMessage } = useToast();
  const { auth } = useAuth();
  const { address: account } = useAccount();
  const chainId = useChainId();
  const publicClient = usePublicClient();
  const [loading, setLoading] = useState(false);
  const [userName, setUserName] = useState('');
  const [agreedToSubscription, setAgreedToSubscription] = useState(false);
  const [showSubscriptionAgreement, setShowSubscriptionAgreement] =
    useState(false);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [subscriptionDocument, setSubscriptionDocument] = useState<Blob | null>(
    null
  );
  const [loadingDocument, setLoadingDocument] = useState<boolean>(false);
  const [investmentAmountFormatted, setInvestmentAmountFormatted] =
    useState<string>('');
  const [legalName, setLegalName] = useState<string>('');

  /**
   * Fetch user info
   */
  useEffect(() => {
    // Get user information
    (async () => {
      try {
        const response = await axios({
          url: `${process.env.REACT_APP_API_URL}/v1/users/me`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'X-Account-Id': auth?.user.account_id,
          },
        });

        if (response.data.result?.legal_name) {
          setLegalName(response.data.result?.legal_name);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  // Function to handle opening the subscription agreement modal
  const handleShowSubscriptionModal = async () => {
    try {
      setLoadingDocument(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/offerings/${offeringId}/documents/subscription_agreement`,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'X-Account-Id': auth?.user.account_id,
          },
          responseType: 'arraybuffer',
        }
      );

      const contentType = response.headers['content-type'];
      const blob = new Blob([response.data], { type: contentType });

      setSubscriptionDocument(blob);
      setLoadingDocument(false);
      setShowSubscriptionModal(true);
    } catch (error) {
      console.error('Error fetching subscription agreement:', error);
      setError('Failed to load subscription agreement.');
      setLoadingDocument(false);
    }
  };

  const handleCloseSubscriptionModal = () => {
    setShowSubscriptionModal(false);
    setSubscriptionDocument(null);
  };

  /**
   * Handle investment amount change
   * @param e
   */
  const handleInvestmentAmountChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value.replace(/,/g, '');
    if (value === '' || /^(\d+\.?\d*)$/.test(value)) {
      setInvestmentAmount(value);
      if (value !== '') {
        const numberValue = parseFloat(value);
        const formattedValue = numberValue.toLocaleString('en-US', {
          maximumFractionDigits: 2,
          useGrouping: true,
        });
        setInvestmentAmountFormatted(formattedValue);
      } else {
        setInvestmentAmountFormatted('');
      }
    }
  };
  const [selectedAccount, setSelectedAccount] = useState<any>(null);

  /**
   * Handle payment method change
   * @param e {React.ChangeEvent<HTMLSelectElement>}
   */
  const handlePaymentMethodChange = async (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setPaymentMethod(e.target.value);
  };

  const { writeContractAsync: approve } = useWriteContract();
  const { writeContractAsync: investContract } = useWriteContract();

  const handleCryptoPayment = async (amountValue: number) => {
    try {
      setLoading(true);
      const paymentTokenAddress = subscriptionTokenAddress[chainId];
      const tokenId = 1;
      const usdcAbi = contracts[chainId!].USDC.abi as Abi;
      const amount = parseUnits(amountValue.toString(), decimals[chainId!]);

      // Check allowance
      let allowance = await publicClient?.readContract({
        address: paymentTokenAddress as `0x${string}`,
        abi: usdcAbi,
        functionName: 'allowance',
        args: [account, contractAddress],
      });

      if (BigInt(allowance as any) < amount) {
        // Approve the RegD506c contract to spend USDC on behalf of the user
        const approveTxHash = await approve({
          address: paymentTokenAddress as `0x${string}`,
          abi: usdcAbi,
          functionName: 'approve',
          args: [contractAddress, amount],
          gas: BigInt(1000000),
        });

        // Wait for the approval transaction to be mined
        await publicClient?.waitForTransactionReceipt({
          hash: approveTxHash,
        });
      }

      // Prepare arguments for the invest function
      const documentHash = '';
      const documentURI = '';

      const args = [amount, documentHash, documentURI];

      // Estimate gas for the invest function
      let gas = await publicClient?.estimateContractGas({
        address: contractAddress as `0x${string}`,
        abi: contracts[chainId!].SafeRegD506b.abi as Abi,
        functionName: 'invest',
        args,
        account,
      });

      // Call the `invest` function on the SafeRegD506b contract
      let txHash = await investContract({
        address: contractAddress as `0x${string}`,
        abi: contracts[chainId!].SafeRegD506b.abi as Abi,
        functionName: 'invest',
        account,
        args,
        gas,
      });

      // Wait for the transaction to be mined
      await publicClient?.waitForTransactionReceipt({
        hash: txHash,
      });

      // Success: update offering in the database with amount invested
      const response = await axios(
        `${process.env.REACT_APP_API_URL}/v1/offerings/${offeringId}/investments`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${auth?.token}`,
            'X-Account-Id': auth?.user.account_id,
          },
          data: {
            tx_hash: txHash,
            amount: Number(investmentAmount),
            token: subscriptionTokenAddress[chainId],
            token_id: tokenId,
          },
        }
      );

      const { result } = response.data;

      // Call parent component's success callback
      onInvestmentSuccess(parseFloat(investmentAmount.toString()));
    } catch (error: any) {
      if (error.code === 'CALL_EXCEPTION') {
        // Decode the revert reason if available
        const errorMessage = error.data.message;
        console.log('Transaction reverted with:', errorMessage);

        // Example: Check for specific custom errors
        if (errorMessage.includes('BelowMinimumInvestment')) {
          // Handle below minimum investment error
          console.error('Investment amount is below minimum');
        } else if (errorMessage.includes('InvestmentCapExceeded')) {
          // Handle investment cap exceeded error
          console.error('Investment cap exceeded');
        } else if (errorMessage.includes('PaymentFailed')) {
          // Handle payment failed error
          console.error('Payment transfer failed');
        } else {
          // Handle unrecognized custom error
          console.error('Unrecognized custom error:', errorMessage);
        }
      }
      console.log(error);
      setError(error);
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  /**
   * TODO: Wire payment via Plaid
   * @returns
   */
  const handleWirePayment = async (amountValue: number) => {
    // const request: TransferAuthorizationCreateRequest = {
    //   access_token: 'access-sandbox-71e02f71-0960-4a27-abd2-5631e04f2175',
    //   account_id: '3gE5gnRzNyfXpBK5wEEKcymJ5albGVUqg77gr',
    //   type: 'debit',
    //   network: 'ach',
    //   amount: '12.34',
    //   ach_class: 'ppd',
    //   user: {
    //     legal_name: 'Anne Charleston',
    //   },
    // };
    try {
      // const response = await client.transferAuthorizationCreate(request);
      // const authorizationId = response.data.authorization.id;

      const response = await axios(
        `${process.env.REACT_APP_API_URL}/v1/offerings/${offeringId}/investments`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${auth?.token}`,
            'X-Account-Id': auth?.user.account_id,
          },
          data: {
            tx_hash: null,
            amount: Number(amountValue),
            token: subscriptionTokenAddress[chainId],
            token_id: null,
          },
        }
      );

      const { result } = response.data;

      // Call parent component's success callback
      onInvestmentSuccess(parseFloat(amountValue.toString()));

      alert('Success!');
    } catch (error) {
      setError(error);
    } finally {
      handleClose();
    }
  };

  const handleAchPayment = async (amountValue: number) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/plaid/transfer`,
        {
          amount: amountValue,
          account_id: selectedAccount.plaid_account_id,
        },
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'X-Account-Id': auth?.user.account_id,
          },
        }
      );
      handleClose();
      alert('Success!');
    } catch (error: any) {
      if (error.response.status === 400) {
        setError(error.response.data.error);
      } else {
        setError(error);
      }
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!agreedToSubscription || !userName) {
      // Optionally handle the error or notify the user
      return;
    }
    const amountValue = parseFloat(investmentAmount.toString());
    if (isNaN(amountValue)) {
      // Handle invalid number input
      setError('Please enter a valid investment amount.');
      return;
    }
    if (paymentMethod === 'crypto') handleCryptoPayment(amountValue);
    if (paymentMethod === 'wire') handleWirePayment(amountValue);
    if (paymentMethod === 'ach') handleAchPayment(amountValue);
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setMessage('Copied to clipboard!');
      })
      .catch((err) => {
        console.error('Failed to copy text: ', err);
        setError('Failed to copy. Please try manually.');
      });
  };

  const onUserNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserName(e.target.value);
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Invest</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formInvestmentAmount" className="mb-3">
              <Form.Label>Investment Amount</Form.Label>
              <InputGroup>
                <Form.Control
                  type="text"
                  placeholder="Enter amount"
                  value={investmentAmountFormatted}
                  onChange={handleInvestmentAmountChange}
                  required
                />
                <InputGroup.Text>
                  {paymentMethod === 'crypto'
                    ? subscriptionToken[chainId]
                    : 'USD'}
                </InputGroup.Text>
              </InputGroup>
            </Form.Group>
            <Form.Group controlId="formPaymentMethod">
              <Form.Label>Payment Method</Form.Label>
              <Form.Select
                value={paymentMethod}
                onChange={handlePaymentMethodChange}
                required
              >
                <option value="ach">ACH</option>
                <option value="creditCard" disabled>
                  Credit Card
                </option>
                <option value="crypto">Crypto</option>
                <option value="wire">Wire</option>
              </Form.Select>
            </Form.Group>
            {['ach'].includes(paymentMethod) && (
              <SelectBankAccount
                selectedAccount={selectedAccount}
                setSelectedAccount={setSelectedAccount}
              />
            )}
            {['wire'].includes(paymentMethod) && (
              <Form.Group controlId="formWirePayment" className="mt-4">
                <Form.Label className="fw-bold">
                  Wire Payment Instructions
                </Form.Label>
                <div className="wire-instructions">
                  {/* Beneficiary Name */}
                  <Form.Label className="mt-3">Beneficiary Name</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="text"
                      value="World Exchange Corp."
                      readOnly
                    />
                    <Button
                      variant="outline-secondary"
                      onClick={() => copyToClipboard('World Exchange Corp.')}
                    >
                      <i className="fa fa-copy"></i>
                    </Button>
                  </InputGroup>

                  {/* Account Number */}
                  <Form.Label>Account Number</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control type="text" value="202340610083" readOnly />
                    <Button
                      variant="outline-secondary"
                      onClick={() => copyToClipboard('202340610083')}
                    >
                      <i className="fa fa-copy"></i>
                    </Button>
                  </InputGroup>

                  {/* Type of Account */}
                  <Form.Label>Type of Account</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control type="text" value="Checking" readOnly />
                    <Button
                      variant="outline-secondary"
                      onClick={() => copyToClipboard('Checking')}
                    >
                      <i className="fa fa-copy"></i>
                    </Button>
                  </InputGroup>

                  {/* Beneficiary Address */}
                  <Form.Label>Beneficiary Address</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="text"
                      value="101 Polk Street, Unit 302, San Francisco, CA 94102"
                      readOnly
                    />
                    <Button
                      variant="outline-secondary"
                      onClick={() =>
                        copyToClipboard(
                          '101 Polk Street, Unit 302, San Francisco, CA 94102'
                        )
                      }
                    >
                      <i className="fa fa-copy"></i>
                    </Button>
                  </InputGroup>

                  {/* ABA Routing Number */}
                  <Form.Label>ABA Routing Number</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control type="text" value="091311229" readOnly />
                    <Button
                      variant="outline-secondary"
                      onClick={() => copyToClipboard('091311229')}
                    >
                      <i className="fa fa-copy"></i>
                    </Button>
                  </InputGroup>

                  {/* Bank Name */}
                  <Form.Label>Bank Name</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="text"
                      value="Choice Financial Group"
                      readOnly
                    />
                    <Button
                      variant="outline-secondary"
                      onClick={() => copyToClipboard('Choice Financial Group')}
                    >
                      <i className="fa fa-copy"></i>
                    </Button>
                  </InputGroup>

                  {/* Bank Address */}
                  <Form.Label>Bank Address</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="text"
                      value="4501 23rd Avenue S, Fargo, ND 58104"
                      readOnly
                    />
                    <Button
                      variant="outline-secondary"
                      onClick={() =>
                        copyToClipboard('4501 23rd Avenue S, Fargo, ND 58104')
                      }
                    >
                      <i className="fa fa-copy"></i>
                    </Button>
                  </InputGroup>
                </div>
              </Form.Group>
            )}
            <Form.Group controlId="formSubscriptionAgreement" className="mt-4">
              <Form.Label>
                Please review and accept the{' '}
                <a
                  href="#"
                  role="button"
                  onClick={handleShowSubscriptionModal}
                  className="text-primary"
                >
                  Subscription Agreement
                </a>
                .
                {loadingDocument && (
                  <Spinner animation="border" size="sm" className="ms-2" />
                )}
              </Form.Label>
              <Form.Check
                type="checkbox"
                // Start of Selection
                label="I hereby certify that I am an accredited investor as defined under applicable law, and I have read and agree to the Subscription Agreement"
                required
                checked={agreedToSubscription}
                onChange={(e) => setAgreedToSubscription(e.target.checked)}
              />
            </Form.Group>

            <Form.Group controlId="formUserName" className="mt-3">
              <Form.Label>Please type your legal name to confirm:</Form.Label>
              <Form.Control
                type="text"
                placeholder="Legal Name"
                value={userName}
                onChange={onUserNameChange}
                required
              />
            </Form.Group>
            <br />
            <div className="text-center">
              <Button
                variant="primary"
                size="lg"
                style={{ width: '100%' }}
                type="submit"
                className="mt-3"
                disabled={
                  loading ||
                  (['ach'].includes(paymentMethod) && !selectedAccount) ||
                  !investmentAmount ||
                  userName !== legalName ||
                  !agreedToSubscription
                }
              >
                {loading ? 'Loading...' : 'Submit'}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Modal to display the Subscription Agreement */}
      <Modal
        show={showSubscriptionModal}
        onHide={handleCloseSubscriptionModal}
        size="lg"
        centered
        dialogClassName="modal-90w"
        aria-labelledby="subscription-agreement-modal"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="subscription-agreement-modal">
            Subscription Agreement
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: '80vh' }}>
          {subscriptionDocument ? (
            <PDFViewer data={subscriptionDocument} />
          ) : (
            <div>Loading document...</div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseSubscriptionModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default InvestModal;
