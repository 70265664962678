// src/pages/Offerings/Documents/SafeOfferingDocuments.tsx

import React, { useState } from 'react';
import { Form, Button, Container } from 'react-bootstrap';
import axios from 'axios';
import { useAuth } from '../../../contexts/AuthContext';
import { useNavigate, useParams } from 'react-router-dom';
import { useOffering } from '../../../contexts/OfferingContext';

const SafeOfferingDocuments: React.FC = () => {
  const { auth } = useAuth();
  const { offeringId } = useParams<{ offeringId: string }>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [safeContract, setSafeContract] = useState<File | null>(null);
  const [pitchDeck, setPitchDeck] = useState<File | null>(null);
  const [optionalDocuments, setOptionalDocuments] = useState<(File | null)[]>(
    []
  );

  const handleSafeContractChange = (file: File | null) => {
    setSafeContract(file);
  };

  const handleOptionalFileChange = (index: number, file: File | null) => {
    setOptionalDocuments((prev) => {
      const newDocs: any = [...prev];
      newDocs[index] = file;
      return newDocs;
    });
  };

  const handlePitchDeckChange = (file: File | null) => {
    setPitchDeck(file);
  };

  const addOptionalDocument = () => {
    setOptionalDocuments((prev) => [...prev, null]);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      setLoading(true);

      // Upload SAFE Contract
      if (safeContract) {
        const formData = new FormData();
        formData.append('files', safeContract);
        formData.append('type', 'Post-money SAFE');

        await axios.post(
          `${process.env.REACT_APP_API_URL}/v1/offerings/${offeringId}/documents`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'X-Account-Id': auth?.user.account_id,
            },
          }
        );
      }

      // Upload Pitch Deck
      if (pitchDeck) {
        const formData = new FormData();
        formData.append('files', pitchDeck);
        formData.append('type', 'Pitch Deck');

        await axios.post(
          `${process.env.REACT_APP_API_URL}/v1/offerings/${offeringId}/documents`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'X-Account-Id': auth?.user.account_id,
            },
          }
        );
      }

      // Upload optional documents
      for (const file of optionalDocuments) {
        if (file) {
          const formData = new FormData();
          formData.append('files', file);
          formData.append('type', 'Optional Document');

          await axios.post(
            `${process.env.REACT_APP_API_URL}/v1/offerings/${offeringId}/documents`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${auth?.token}`,
                'X-Account-Id': auth?.user.account_id,
              },
            }
          );
        }
      }

      navigate(`/offerings/${offeringId}/safe_terms/create`);
    } catch (error) {
      console.error('Error uploading documents:', error);
      // Handle error
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container className="p-5">
      <h2>Upload SAFE Offering Documents</h2>
      <Form onSubmit={handleSubmit}>
        {/* SAFE Contract Upload */}
        <Form.Group controlId="Postmoney Safe" className="mt-4">
          <Form.Label>SAFE Contract</Form.Label>
          <Form.Control
            type="file"
            name="Postmoney Safe"
            onChange={(e: any) =>
              handleSafeContractChange(e.target.files?.[0] || null)
            }
            required
          />
        </Form.Group>

        {/* Pitch Deck Upload */}
        <Form.Group controlId="Pitch Deck" className="mt-4">
          <Form.Label>Pitch Deck</Form.Label>
          <Form.Control
            type="file"
            name="Pitch Deck"
            onChange={(e: any) =>
              handlePitchDeckChange(e.target.files?.[0] || null)
            }
            required
          />
        </Form.Group>

        {/* Optional Documents */}
        <h3 className="mt-4">Optional Documents</h3>
        {optionalDocuments.map((file, index) => (
          <Form.Group key={index} className="mt-4">
            <Form.Label>Optional Document {index + 1}</Form.Label>
            <Form.Control
              type="file"
              onChange={(e: any) =>
                handleOptionalFileChange(index, e.target.files?.[0] || null)
              }
            />
          </Form.Group>
        ))}
        <Button
          variant="secondary"
          onClick={addOptionalDocument}
          className="mt-4"
        >
          Add Optional Document
        </Button>

        <Button
          variant="primary"
          type="submit"
          className="ms-3 mt-4"
          disabled={loading}
        >
          {loading ? 'Saving...' : 'Save and Continue'}
        </Button>
      </Form>
    </Container>
  );
};

export default SafeOfferingDocuments;
