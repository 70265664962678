import { useState } from 'react';
import { Card } from 'react-bootstrap';
import axios from 'axios';
import { useAuth } from '../../contexts/AuthContext';
import email from './email.png';

export default function ConfirmEmail() {
  const { auth } = useAuth();
  const [sent, setSent] = useState<boolean>(false);

  const resendVerificationEmail = async () => {
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_API_URL}/v1/users/me/resend_verification_email`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'X-Account-Id': auth?.user.account_id,
        },
      });

      if (response.status === 200) {
        // Success message or actions
        setSent(true);
      } else {
        // Handle other statuses if necessary
      }
    } catch (error: any) {
      console.error(error);
      alert('Failed to resend verification email. Please try again later.');
    }
  };

  return (
    <div className="vh-100 bg-primary">
      <div className="h-100 d-flex flex-column align-items-center justify-content-center mx-2">
        <div className="col-lg-4 col-12">
          <div className="d-flex justify-content-center align-items-center">
            <Card className="w-100">
              <Card.Body>
                <div className="row mt-3">
                  <div className="col-12 text-center">
                    {/* <img
                      src={logo}
                      alt="CapSign"
                      className="img-fluid"
                    /> */}
                    <h1 className="my-4">Confirm Email</h1>
                    <img className="img-fluid mb-3" width="150" src={email} />
                  </div>
                </div>
                <div className="row">
                  <div className="offset-2 col-8">
                    <hr />
                    <p className="text-center py-4">
                      Please check your email to continue.
                    </p>
                    {/* Resend Email Section */}
                    <div className="mb3">
                      <div className="text-center py-3 rounded-3">
                        <small>Haven't received your email?</small>
                        <div className="mb-3">
                          <small className="text-muted">
                            Make sure to check your spam folder. Otherwise,
                            click below to resend the email.
                          </small>
                        </div>
                        <button
                          className="btn btn-primary"
                          disabled={sent}
                          onClick={resendVerificationEmail}
                        >
                          Resend
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}
