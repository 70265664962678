// src/pages/Offerings/EditOffering/EditSafeOfferingDocuments.tsx

import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { useAuth } from '../../../contexts/AuthContext';
import { useNavigate, useParams } from 'react-router-dom';
import { useOffering } from '../../../contexts/OfferingContext';

const EditSafeOfferingDocuments: React.FC = () => {
  const { auth } = useAuth();
  const { offeringId } = useParams<{ offeringId: string }>();
  const { offering } = useOffering();
  const navigate = useNavigate();

  const [safeContract, setSafeContract] = useState<File | null>(null);
  const [safeContractUrl, setSafeContractUrl] = useState<string | null>(null);
  const [optionalDocuments, setOptionalDocuments] = useState<(File | null)[]>(
    []
  );
  const [existingOptionalDocuments, setExistingOptionalDocuments] = useState<
    string[]
  >([]);
  const [pitchDeck, setPitchDeck] = useState<File | null>(null);
  const [pitchDeckUrl, setPitchDeckUrl] = useState<string | null>(null);

  useEffect(() => {
    // Fetch existing documents
    const fetchExistingDocuments = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/offerings/${offeringId}/documents`,
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'X-Account-Id': auth?.user.account_id,
            },
          }
        );

        response.data.result.forEach((doc: any) => {
          if (doc.document_type === 'Post-money SAFE') {
            setSafeContractUrl(doc.document_url);
          } else if (doc.document_type === 'Optional Document') {
            setExistingOptionalDocuments((prev) => [...prev, doc.document_url]);
          } else if (doc.document_type === 'Pitch Deck') {
            setPitchDeckUrl(doc.document_url);
          }
        });
      } catch (error) {
        console.error('Error fetching existing documents:', error);
      }
    };

    fetchExistingDocuments();
  }, [auth, offeringId]);

  const handleSafeContractChange = (file: File | null) => {
    setSafeContract(file);
  };

  const handleOptionalFileChange = (index: number, file: File | null) => {
    setOptionalDocuments((prev) => {
      const newDocs = [...prev];
      newDocs[index] = file;
      return newDocs;
    });
  };

  const addOptionalDocument = () => {
    setOptionalDocuments((prev) => [...prev, null]);
  };

  const handlePitchDeckChange = (file: File | null) => {
    setPitchDeck(file);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      // Upload Postmoney Safe if changed
      if (safeContract) {
        const formData = new FormData();
        formData.append('files', safeContract);
        formData.append('type', 'Post-money SAFE');

        await axios.put(
          `${process.env.REACT_APP_API_URL}/v1/offerings/${offeringId}/document`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'X-Account-Id': auth?.user.account_id,
            },
          }
        );
      }

      // Upload Pitch Deck if changed
      if (pitchDeck) {
        const formData = new FormData();
        formData.append('files', pitchDeck);
        formData.append('type', 'Pitch Deck');

        await axios.put(
          `${process.env.REACT_APP_API_URL}/v1/offerings/${offeringId}/document`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'X-Account-Id': auth?.user.account_id,
            },
          }
        );
      }

      // Upload new optional documents
      for (const file of optionalDocuments) {
        if (file) {
          const formData = new FormData();
          formData.append('files', file);
          formData.append('type', 'Optional Document');

          await axios.put(
            `${process.env.REACT_APP_API_URL}/v1/offerings/${offeringId}/document`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${auth?.token}`,
                'X-Account-Id': auth?.user.account_id,
              },
            }
          );
        }
      }

      navigate(`/offerings/${offeringId}/edit/safe_terms`);
    } catch (error) {
      console.error('Error uploading documents:', error);
    }
  };

  return (
    <Container fluid className="p-5">
      <Row>
        <Col>
          <h2>Edit SAFE Offering Documents</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form onSubmit={handleSubmit}>
            {/* Postmoney Safe Upload */}
            <Form.Group controlId="SAFE_Contract">
              <Form.Label>Postmoney Safe</Form.Label>
              {safeContractUrl && (
                <div>
                  <a
                    href={safeContractUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Existing Postmoney Safe
                  </a>
                </div>
              )}
              <Form.Control
                type="file"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleSafeContractChange(e.target.files?.[0] || null)
                }
              />
              <Form.Text className="text-muted">
                {safeContractUrl
                  ? 'Upload a new file to replace the existing Postmoney Safe.'
                  : 'No existing Postmoney Safe. Please upload one.'}
              </Form.Text>
            </Form.Group>

            {/* Pitch Deck Upload */}
            <Form.Group controlId="Pitch_Deck">
              <Form.Label>Pitch Deck</Form.Label>
              {pitchDeckUrl && (
                <div>
                  <a
                    href={pitchDeckUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Existing Pitch Deck
                  </a>
                </div>
              )}
              <Form.Control
                type="file"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handlePitchDeckChange(e.target.files?.[0] || null)
                }
              />
              <Form.Text className="text-muted">
                {pitchDeckUrl
                  ? 'Upload a new file to replace the existing Pitch Deck.'
                  : 'No existing Pitch Deck. Please upload one.'}
              </Form.Text>
            </Form.Group>

            {/* Optional Documents */}
            <h3 className="mt-4">Optional Documents</h3>
            {existingOptionalDocuments.map((url, index) => (
              <div key={index}>
                <a href={url} target="_blank" rel="noopener noreferrer">
                  View Optional Document {index + 1}
                </a>
              </div>
            ))}
            {optionalDocuments.map((file, index) => (
              <Form.Group key={index}>
                <Form.Label>
                  New Optional Document{' '}
                  {existingOptionalDocuments.length + index + 1}
                </Form.Label>
                <Form.Control
                  type="file"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleOptionalFileChange(index, e.target.files?.[0] || null)
                  }
                />
              </Form.Group>
            ))}
            <Button variant="secondary" onClick={addOptionalDocument}>
              Add New Optional Document
            </Button>

            <Button variant="primary" type="submit" className="ms-3">
              Save Changes
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default EditSafeOfferingDocuments;
