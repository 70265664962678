// src/pages/Offerings/EditOffering/EditOfferingDocuments.tsx

import React, { useEffect } from 'react';
import { useOffering } from '../../../contexts/OfferingContext';
import EditSafeOfferingDocuments from './EditSafeOfferingDocuments';
import EditEquityOfferingDocuments from './EditEquityOfferingDocuments';
import { useParams } from 'react-router-dom';

const EditOfferingDocuments: React.FC = () => {
  const { offering, fetchOffering } = useOffering();
  const { offeringId } = useParams<{ offeringId: string }>();

  useEffect(() => {
    if (offeringId && !offering) {
      fetchOffering(offeringId);
    }
  }, [fetchOffering, offeringId, offering]);

  if (offering?.offering_type === 'Equity') {
    return <EditEquityOfferingDocuments />;
  } else if (
    ['Post-money SAFE', 'Pre-money SAFE'].includes(offering?.offering_type)
  ) {
    return <EditSafeOfferingDocuments />;
  }

  // Handle other offering types or provide a default case
  return <div>Unsupported offering type: {offering?.offering_type}</div>;
};

export default EditOfferingDocuments;
