import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PDFViewer from './PDFViewer';
import { useAuth } from '../../../contexts/AuthContext';
import { useOutletContext, useParams } from 'react-router-dom';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { formatOptions } from '../../../constants';
import numbro from 'numbro';

export default function OfferingDetails() {
  const { offering } = useOutletContext<{ offering: any }>();
  const [pdfData, setPdfData] = useState<any>(undefined);
  const { auth } = useAuth();
  const { offeringId } = useParams();

  /**
   * Fetch the current offering document
   */
  useEffect(() => {
    const fetchOfferingDocuments = async () => {
      try {
        if (pdfData) return;

        let response = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/offerings/${offeringId}/documents`,
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'X-Account-Id': auth?.user.account_id,
            },
          }
        );

        // Pitch Deck
        const documentId = response.data.result.filter(
          (r: any) => r.document_type === 'Pitch Deck'
        )[0]?.document_id;

        if (documentId) {
          response = await axios.get(
            `${process.env.REACT_APP_API_URL}/v1/offerings/${offeringId}/documents/${documentId}`,
            {
              headers: {
                Authorization: `Bearer ${auth?.token}`,
                'X-Account-Id': auth?.user.account_id,
              },
              responseType: 'arraybuffer',
            }
          );
          setPdfData({ data: new Uint8Array(response.data) });
        }
      } catch (error) {
        console.error('Error fetching offering:', error);
      }
    };

    fetchOfferingDocuments();
  }, []);

  return (
    <Container>
      {offering.offering_type === 'Equity' && (
        // Render content specific to Equity offerings
        <div>
          <h3>Equity Offering Details</h3>
          <PDFViewer data={pdfData} />
        </div>
      )}
      {['Post-money SAFE', 'Pre-money SAFE'].includes(
        offering.offering_type
      ) && (
        <>
          <PDFViewer data={pdfData} />
          <Card className="shadow-sm mb-4">
            <Card.Body>
              <h3>SAFE Offering Terms</h3>
              <Row className="py-2">
                <Col md={6}>
                  <Card.Title>Discount</Card.Title>
                  <Card.Text>{offering.safe_class?.discount}%</Card.Text>
                </Col>
                <Col md={6}>
                  <Card.Title>Valuation Cap</Card.Title>
                  <Card.Text>${offering.safe_class?.valuation_cap}</Card.Text>
                </Col>
              </Row>
              <Row className="py-2">
                <Col md={6}>
                  <Card.Title>Most Favored Nation</Card.Title>
                  <Card.Text>
                    {offering.safe_class?.most_favored_nation ? 'Yes' : 'No'}
                  </Card.Text>
                </Col>
                <Col md={6}>
                  <Card.Title>Seniority</Card.Title>
                  <Card.Text>{offering.safe_class?.seniority}</Card.Text>
                </Col>
              </Row>
              <Row className="py-2">
                <Col md={6}>
                  <Card.Title>Board Approval Date</Card.Title>
                  <Card.Text>
                    {new Date(
                      offering.safe_class?.board_approval_date
                    ).toLocaleDateString()}
                  </Card.Text>
                </Col>
                <Col md={6}>
                  <Card.Title>Stockholder Approval Date</Card.Title>
                  <Card.Text>
                    {offering.safe_class?.stockholder_approval_date
                      ? new Date(
                          offering.safe_class?.stockholder_approval_date
                        ).toLocaleDateString()
                      : 'N/A'}
                  </Card.Text>
                </Col>
              </Row>
              <Row className="py-2">
                <Col md={6}>
                  <Card.Title>Consideration Text</Card.Title>
                  <Card.Text>
                    {offering.safe_class?.consideration_text || 'N/A'}
                  </Card.Text>
                </Col>
                <Col md={6}>
                  <Card.Title>Pro Rata Rights</Card.Title>
                  <Card.Text>
                    {offering.safe_class?.pro_rata_percentage || 'N/A'}
                  </Card.Text>
                </Col>
              </Row>
              <Row className="py-2">
                <Col md={6}>
                  <Card.Title>Conversion Triggers</Card.Title>
                  <Card.Text>
                    {offering.safe_class?.conversion_triggers || 'N/A'}
                  </Card.Text>
                </Col>
                <Col md={6}>
                  <Card.Title>Security Law Exemptions</Card.Title>
                  <Card.Text>{offering?.federal_exemption}</Card.Text>
                </Col>
              </Row>
              <Row className="py-2">
                <Col md={12}>
                  <Card.Title>Currency</Card.Title>
                  <Card.Text className="text-truncate">
                    {offering.safe_class?.currency || 'N/A'}
                  </Card.Text>
                </Col>
              </Row>
              <Row className="py-2">
                <Col md={12}>
                  <Card.Title>Metadata URI</Card.Title>
                  <Card.Text>{offering.safe_class?.uri || 'N/A'}</Card.Text>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </>
      )}
    </Container>
  );
}
